angular.module('search')
    .service('SearchResults', function($rootScope, $window){
        var searchResults = [];
        var searchPhrase = '';
        var searchCategoryId = '';

        $window.rootScopes = $window.rootScopes || [];
        $window.rootScopes.push($rootScope);

        if (!!$window.sharedService){
            return $window.sharedService;
        }

        $window.sharedService = {
            change: function(newPhrase, newResults, categoryId){
                searchPhrase = newPhrase;
                searchResults = newResults;
                searchCategoryId = categoryId;
                angular.forEach($window.rootScopes, function(scope) {
                    if(!scope.$$phase) {
                        scope.$apply();
                    }
                });
            },
            getSearchPhrase: function(){
                return searchPhrase;
            },
            getSearchResults: function(){
                return searchResults;
            },
            getCategoryId: function(){
                return searchCategoryId;
            }
        }

        return $window.sharedService;
    });
/* global angular, globalItemListCategoryId */
(function () {
    "use strict";

    angular.module("search")
        .controller("SearchController", SearchController);

    SearchController.$inject = ["$http", "SearchResults"];

    function SearchController($http, searchResults) {
        var vm = this;

        vm.searchSuggestion = undefined;

        vm.changeSearchResults = changeSearchResults;
        vm.getSearchSuggestions = getSearchSuggestions;
        vm.applySuggestion = applySuggestion;
        vm.noResults = false;

        function changeSearchResults(phrase, itemIds, categoryId) {
            searchResults.change(phrase, itemIds, categoryId);
        }

        function getSearchSuggestions(phrase) {
            return $http.get('/ajax/search/search.php', {
                params: {
                    searchPhrase: phrase
                }
            }).then(function(response){
                return response.data.results.map(function(item){
                    return item;
                });
            });
        }

        /**
         *
         * @param {{itemIds:array, url:string, categoryId:int, phrase:string}} $item
         * @param {{itemIds:array, url:string, categoryId:int, phrase:string}} $model
         * @param $label string
         */
        function applySuggestion($item, $model, $label) {
            if($item.itemIds.length === 1) {
                window.location = $item.url;
                return;
            }

            if(typeof globalItemListCategoryId !== "undefined" && parseInt(globalItemListCategoryId) !== 1) {
                vm.changeSearchResults($item.phrase, $item.itemIds, $item.categoryId);
                return;
            }

            if (!Modernizr.localstorage) {
                window.location = $item.url;
                return;
            }

            var searchTransfer = {};
            searchTransfer.phrase = $item.phrase;
            searchTransfer.itemIds = $item.itemIds;
            localStorage.setItem("searchTransfer", JSON.stringify(searchTransfer));
            window.location = $item.url;
            return;
        }
    }
})();